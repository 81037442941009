import React from 'react'
import Slider from './Slider'
import ApproachContent from './ApproachContent'



function Approach() {
  return (
    <div>
    <Slider pageName="OUR APPROACH">
    </Slider>
  
    <br/>

    <div class="text-center  animate__heartBeat">
              <h1 class="section-title  bg-white text-center px-3 ">OUR APPROACH</h1>
          </div>
<br/>
<ApproachContent/>
    

  </div>
  )
}

export default Approach
