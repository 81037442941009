import React, { useState, useEffect } from "react";
import {Routes, Route, useNavigate} from 'react-router-dom';
import styled from "styled-components";
import Button from "react-bootstrap/Button";

import "bootstrap/dist/css/bootstrap.min.css";

import '../css/Home.css';

import image1 from "../../Images/Swagbox.png";

import image2 from "../../Images/event1/image1.png";
import image3 from "../../Images/event1/image2.jpg";
import image4 from "../../Images/event1/image3.jpg";
import image5 from "../../Images/event1/image4.jpg";
import ImageSlider from "./ImageSlider";
import Landingpage from "./Landingpage";
import ClientsLogo from "./ClientsLogo";


function Home() {

    const navigate = useNavigate();

    const navigateServices = () => {
        //  navigate to /
        navigate('/services');
      };

      const navigateContact = () => {
        //  navigate to /
        navigate('/contact');
      };


  return (
        <>
        
            <div className="container1">
            <ImageSlider images={[ image1, image2, image3, image4, image5]}>
            
            <h1 style={{
              display: "flex",
                fontSize:"40px",
                textAlign:"center",
              color: "#fff"
            }}className="animate__animated animate__backInDown">CHOOSE US AND CELEBRATE A SPECIAL OCCASION IN STYLE</h1>
            <br/>
       
            < p style={{
              display: "flex",
              flexDirection: "column",
                fontSize:"20px",
                textAlign:"center",
              color: "#fff"
             
            }} className="animate__animated animate__backInDown">Our professional team will plan a party that’s perfect for you and your guests</p>
            <br/>
            <div style={{
              display: "flex",
             alignItems:"center",
            }}>
            </div>
            <div className='homebtn animate__animated animate__backInUp' style={{
              display: "flex",
             alignItems:"center",
            }}>
            </div>
      
        
          
            </ImageSlider>

            <Landingpage/>
            {/* <ClientsLogo/> */}
            
        </div>
        </>
    );
};
export default Home
