import First from "../../Images/Swagbox.png";
import Second from "../../Images/event1/image1.png";
import Third from "../../Images/event1/image2.jpg";
import Fourth from "../../Images/event1/image3.jpg";
import Fifth from "../../Images/event1/image4.jpg";


export default [
  {
    
    urls: First,
  },
  {
    urls: Second,
  },
  {
    urls: Third,
  },
  {
    urls: Fourth,
  },
  {
    urls: Fifth,
  },
  
];
