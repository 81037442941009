import React from 'react'
import '../css/cardhover.css';
import back1 from '../../Images/back1.jpg'
import back2 from '../../Images/back5.jpg'
import back3 from '../../Images/back6.jpg'
import { useNavigate} from 'react-router-dom';

const Cardhovereffect = () => {
    const navigate = useNavigate();

    const navigateContact = () => {
        //  navigate to /
        navigate('/contact');
      };
  return (
    <div class="container">
    <div class="row row-cols-1 g-5 row-cols-md-3 row-cols-lg-3">
        <div class="col animate__animated animate__slideInLeft">
            <div class="card overflow-hidden shadow border-0" id="card_Hover">
                <img class="card-img-top" style={{width: "100%", height: "370px"}} src={back1} alt=""/>
                <div class="card-body bg-dark text-white bg-opacity-100 w-100" style={{opacity: "0.9", width: "100%", height: "300px"}} id="Card_Text">
                    <h4 class="card-title text-center text-capitalize fw-bold pb-2 headercontent">PLANNING</h4>
                    <p class="card-text text-center paracontent">
                    Are you looking to celebrate a special occasion or event in style? 
                Whether you're planning an intimate soiree or a lavish gala, we've got you covered.
                We work collaboratively to ensure your event is cohesive and thoughtful.
                    </p>
                    <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill' onClick={navigateContact}>Contact Us</button></div>
        
                </div>
            </div>
        </div>
        <div class="col animate__animated animate__slideInUp">
        <div class="card overflow-hidden shadow border-0" id="card_Hover">
                <img class="card-img-top" style={{width: "100%", height: "370px"}} src={back2} alt=""/>
                <div class="card-body bg-dark text-white bg-opacity-100 w-100" style={{opacity: "0.9",width: "100%", height: "300px"}} id="Card_Text">
                    <h4 class="card-title text-center text-capitalize fw-bold pb-2 headercontent">MANAGEMENT</h4>
                    <p class="card-text text-center paracontent">
                    With the master plan in place,
                    we handles everything from negotiations and logistics to design and catering for events of all kinds,
                    we believe that even the smallest details can have a huge impact.
                    </p>
                    <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill' onClick={navigateContact}>Contact Us</button></div>
        
                </div>
            </div>
        </div>
        <div class="col animate__animated animate__slideInRight">
        <div class="card overflow-hidden shadow border-0" id="card_Hover">
                <img class="card-img-top" style={{width: "100%", height: "370px"}} src={back3} alt=""/>
                <div class="card-body bg-dark text-white bg-opacity-100 w-100" style={{opacity: "0.9",width: "100%", height: "300px"}} id="Card_Text">
                    <h4 class="card-title text-center text-capitalize fw-bold pb-2 headercontent">EXECUTION</h4>
                    <p class="card-text text-center paracontent">
                    Swagbox Events team executes each step with skill and precision,
                with every last details taken care of, we can ensure your big day is effortless and unforgettable.
                    </p>
                    <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill' onClick={navigateContact}>Contact Us</button></div>
        
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Cardhovereffect
