import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import '../css/CommomHeder.css'

const AboutusContent = () => {
  return (
    <div>
        <div class="row aboutcontent animate__animated animate__zoomIn"> 
        “Swagbox provides full-service event and destination management knowledge and expertise that draws on the vision of our clients and partners without compromising our number one priority: stellar and superior customer service.
       <br/>
       <br/>
        <p>Swagbox’s process-driven approach is specifically geared toward taking on complex events requiring substantial creativity and strategic vision.
         We offer our expertise and guidance whenever needed to ensure success and treat each event as a true collaboration. 
         In addition, we actively manage event budgets in order to maximize value without exceeding allocated expenditures.”
         </p>
        </div>
   

    </div>
  )
}

export default AboutusContent
