import React from 'react'
import event1 from '../../Images/Clients_logo/ib.jpg';
import event2 from '../../Images/Clients_logo/hp.png';
import event3 from '../../Images/Clients_logo/HavellsLogo.png';
import event4 from '../../Images/Clients_logo/usha.png';
import event5 from '../../Images/Clients_logo/MuthootFinance.png';
import event6 from '../../Images/Clients_logo/DNA-Logo.png';
import event7 from '../../Images/Clients_logo/centuryply.png';
import event8 from '../../Images/Clients_logo/axisbank1.png';
import event9 from '../../Images/Clients_logo/RelianceJio.png';
import event10 from '../../Images/Clients_logo/em.png';
import event11 from '../../Images/Clients_logo/hripl1.png';


import '../css/PopUpImage.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

const ClientsLogo = () => {
    return (
        <div className='view'>
             <div className='content1 text-center animate__animated animate__tada'>We Work With the Best Partners</div>
             {/* <div className='aboutcontent animate__animated animate__flash'>We Work With the Best Partners.</div> */}
              
                   <div class="client-image-container">
              
                   <div class="image animate__animated animate__rollIn">
                      <img src={event1} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event2} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event3} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event4} class="gallery-item" alt="gallery"/>
                   </div>
                   {/* <div class="image animate__animated animate__rollIn">
                   <img src={event5} class="gallery-item" alt="gallery"/>
                   </div> */}
                   <div class="image animate__animated animate__rollIn">
                   <img src={event6} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                      <img src={event7} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event8} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event9} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event10} class="gallery-item" alt="gallery"/>
                   </div>
                   <div class="image animate__animated animate__rollIn">
                   <img src={event11} class="gallery-item" alt="gallery"/>
                   </div>
                  
              
             </div>

        </div>
    
      
      )
}

export default ClientsLogo











