import React from 'react'
import { Navigate, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Navbar from './Navbar'


function Header() {
  return (
   <MainHeader>
    <NavLink to = '/'>
    <img className='logoval' height="90" width="150" src={require('../../Images/swagbox_logo.png')} alt='Welcome to SwagBox' title='' />
   
    </NavLink>
    <Navbar/>
   </MainHeader>
   
   
  )
}

const MainHeader = styled.header`
padding: 0 3.2rem;
height: 10rem;
background-color: ${({ theme })=> theme.colors.bacground};
display: flex;
justify-content: space-between;
align-items: center;


`;
export default Header
