import React from 'react'
import '../css/CommomHeder.css'
import { useNavigate} from 'react-router-dom';
import Cardhovereffect from './Cardhovereffect';

const ApproachContent = () => {
    const navigate = useNavigate();

    const navigateContact = () => {
      //  navigate to /
      navigate('/contact');
    };
  return (
    <div>
        <p className='spancontent text-center animate__animated animate__rollIn'> WHY SWAGBOX?</p>
        <div className='row aboutcontent animate__animated animate__slideInRight'>
        Choose us and celebrate a special occasion in style. Our professional team will plan a party that’s perfect for you and your guests.
         Call today to schedule your free consultation with one of our event experts.
        </div>
        <hr/>
        <br/>
        <div className='row approachcontent animate__animated animate__flash'>
            <div className='col-md-12'>
                    
                    <p className='spancontent animate__animated animate__rollIn'> WHAT IS SWAGBOX?</p>
                    <h3 className='text-center animate__animated animate__rollIn'><strong>WE PLAN AND DELIVER</strong></h3>
                    <div className='row aboutcontent animate__animated animate__slideInLeft'>
                    Based in Navi Mumbai,
                     Swag Box Media provides an affordable stress-free alternative to event planning, 
                     catering and special events. Our team is on standby 24/7 and ready to handle your special event.
                </div>
                
                
                    
            </div>
            {/* <div className='col-md-6'>
                <div className='approachimg'>

                </div>
            </div> */}

        </div>
        <div className='row animate__animated animate__heartBeat'>
            <div className='rowarea'>
                <p className='text-center'>We work not to meet the benchmark but to create them 🤘</p>
                <div className='squarediv'></div>
            </div>
        </div>
      
{/* 
        <div class="row">
            <div class="col-md-4 approachcard1">
                <div></div>
            <div className='d-flex align-items-center justify-content-center'>
                    <img src=""/>
                </div>
                <p className='text-center headercontent'>PLANNING</p>
                <p className='text-center paracontent '>
                Are you looking to celebrate a special occasion or event in style? 
                Whether you're planning an intimate soiree or a lavish gala, we've got you covered.
                We work collaboratively to ensure your event is cohesive and thoughtful.
                </p>
                <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill ' onClick={navigateContact}>Contact Us</button></div>
              </div>
            <br/>
         
            <div class="col-md-4  approachcard2 ">
                <div className='d-flex align-items-center justify-content-center image'>
                    <img src=""/>
                </div>
                <div className='cardbody'>
                <p className='text-center headercontent'>MANAGEMENT</p>
                <p className='text-center paracontent'>
                    With the master plan in place,
                    we handles everything from negotiations and logistics to design and catering for events of all kinds,
                    we believe that even the smallest details can have a huge impact.
                    
                </p>
                <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill ' onClick={navigateContact}>Contact Us</button></div>
              </div>
              </div>

              <div class="col-md-4 approachcard3 ">
              <div className='d-flex align-items-center justify-content-center'>
                    <img src=""/>
                </div>
                <p className='text-center headercontent'>EXECUTION</p>
                <p className='text-center paracontent'>
                
                Swagbox Events team executes each step with skill and precision,
                with every last details taken care of, we can ensure your big day is effortless and unforgettable.
                </p>
                <div className='d-flex align-items-center justify-content-center'><button className='viewbtn  rounded-pill ' onClick={navigateContact}>Contact Us</button></div>
             </div>
             
             </div> */}
             <Cardhovereffect/>
    </div>
  )
}

export default ApproachContent
