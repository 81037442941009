import React from 'react'
import AboutusContent from './AboutusContent'
import Slider from './Slider'
import '../css/CommomHeder.css'
import "bootstrap/dist/css/bootstrap.min.css";


function About() {
  return (
    <div>
      <Slider pageName="ABOUT US">
      </Slider>
      <br/>

      <div class="text-center  animate__heartBeat">
                <h1 class="section-title  bg-white text-center px-3 ">About Us</h1>
            </div>
<br/>
<AboutusContent/>

    </div>
  )
}

export default About
