import React from 'react'
import '../css/Home.css';
import { useNavigate} from 'react-router-dom';
import Cardhovereffect from './Cardhovereffect';
import Card from './Card';
import EventPhoto from './EventPhoto';
import ClientsLogo from './ClientsLogo';

const Landingpage = () => {
    const navigate = useNavigate();

    const navigateContact = () => {
        //  navigate to /
        navigate('/contact');
      };

    const navigateServices = () => {
        //  navigate to /
        navigate('/services');
      };
  return (
 <div className='bgimg'>
        <p className='spancontent text-center animate__animated animate__bounce'> WHY SWAGBOX ?</p>
        <div className='row aboutcontent animate__animated animate__lightSpeedInLeft'>
        Our professional team will plan a party that’s perfect for you and your guests.
         Call today to schedule your free consultation with one of our event experts.
        </div>
        <div className='d-flex align-items-center justify-content-center animate__animated animate__rollIn'><button className='viewbtnhome rounded-pill' onClick={navigateServices}>VIEW SERVICES</button></div>
        <br/>
         <div className='col-md-12'>
                    
                    <p className='spancontent animate__animated animate__bounce' > WHAT IS SWAGBOX?</p>
                    <p className='aboutcontent animate__animated animate__lightSpeedInRight'>
                    Based in Navi Mumbai,
                     Swag Box Media provides an affordable stress-free alternative to event planning, 
                     catering and special events. Our team is on standby 24/7 and ready to handle your special event.
                    </p>
                    
                
                    
            </div>
            <div className='d-flex align-items-center justify-content-center animate__animated animate__rollIn '><button className='viewbtnhome rounded-pill' onClick={navigateContact}>GET IN TOUCH</button></div>
           
        <br/>
       <Cardhovereffect/>
       <div className='row approachcontent animate__animated animate__flash'>
            <div className='col-md-12'>
                    
                    <p className='spancontent animate__animated animate__rubberBand'>OUR SUCCESSFUL EVENTS</p>
                    <h3 className='text-center'><strong>WE PLAN AND DELIVER</strong></h3>
                    <p className='text-center contentapp'>
                    Our professional event planning staff will ensure your celebration is everything you’ve ever dreamed of. 
                    From elegant table settings and personalised favors, to delectable food and perfectly matched wine and beer,
                     we’ll work with you to create a party that’s as special as the guest of honor.
                    </p>
                  
            </div>    
                </div>
      <EventPhoto/>
        </div>
  )
}

export default Landingpage
