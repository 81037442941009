import React from 'react'
import'../css/Contact.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate} from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName ,
    FaPhoneAlt, 
    FaRegEnvelope,
    FaMapMarkerAlt,
    FaArrowAltCircleLeft,
    FaInstagramSquare,
    FaFacebookSquare
  } 
    from "react-icons/fa";



function ContactusForm() {
  const navigate = useNavigate();

    const navigateHome = () => {
        //  navigate to /
        navigate('/');
      };
  return (
    <div>
     
        <div class="row">
         
            <div class="col-md-5 animate__animated animate__backInLeft">
              <h3 className='contact-headers'>Get in touch</h3><hr/>
              <div class="d-flex-header" >SwagBox</div>
              <div class="mt-4">
                  <div class="d-flex">
                  <FaMapMarkerAlt className='icons'/>
                    <p class="d-flex-header" >Address: <span className='value'>637 Grohitam premises sector 19 Vashi,Navi Mumbai</span></p>
                  </div><hr/>
                  <div class="d-flex">
                  <FaPhoneAlt className='icons'/>
                    <p class="d-flex-header">Contact :- <span className='value'>+917400009696</span></p>
                  </div><hr/>
                  <div class="d-flex">
                  <FaRegEnvelope className='icons'/>
                    <p class="d-flex-header">Email:- <span className='value'>ritesh@swagbox.in</span></p>
                  </div><hr/>
                  <div class="d-flex">
                  <FaArrowAltCircleLeft className='icons'/>
                    <p class="d-flex-header">Website :- <a href="" onClick={navigateHome}><span className='value'>www.swagbox.in</span></a></p>
                  </div><hr/>
                  <div class="d-flex">
                  < FaInstagramSquare className='icons'/>
                    <p class="d-flex-header">Insta :- <a href="https://www.instagram.com/swagboxmedia/"><span className='value'>swagboxmedia</span></a></p>
                  </div><hr/>
                  {/* <div class="d-flex">
                  < FaFacebookSquare className='icons'/>
                    <p class="d-flex-header">FB :- <span className='value'>swagboxmedia</span></p>
                  </div> <hr/> */}
                 
                  

                  
              </div>
            </div>
            <div className='col-md-7 animate__animated animate__backInRight '>
                <div className='approachimg'>

                </div>
            </div>
        </div>
        <div className='content1 text-center animate__animated animate__tada'>LOCATION
         </div>
        <div className='row'>
          <div className='col-12 map'> 
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.6659330629764!2d73.0090493!3d19.0784201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c1947824c383%3A0x5910e7d48bcab6e0!2sSwagBox%20Media!5e0!3m2!1sen!2sin!4v1682361766783!5m2!1sen!2sin" 
        width="100%" 
        height="400" 
        style={{border:"0"}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade">

        </iframe>
        </div>

        </div>

        

  
    </div>
  )
}

export default ContactusForm
